/* eslint-disable @nx/enforce-module-boundaries */
import {
  Stack,
  VStack,
  Box,
  HStack,
  Link
} from '@mybridge/ui';
import React, { useEffect, useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PostingUser from 'v4/components/post-form/posting-user';
import SectionLoader from 'v4/components/common/loader/sectionLoader';
import { PostFormContext } from 'v4/components/post-form/context';
import { useRouter } from 'next/router';
import {
  setPublishingToData,
  setPublishingAsData,
} from 'v4/store/slices/blogSlice';
import { AddIcon } from '@chakra-ui/icons';
import { Heading } from '@chakra-ui/react';
import { getAllCompanyPages } from 'v4/store/actions/companyPages.actions';
import { useTranslator } from '@mybridge/ui/custom-hooks/useTranslation';

const PostAsComponent = ({ onClose, setSelectedUser , selectedUser }) => {
  const dispatch = useDispatch();
  const { t } = useTranslator();
  const { companyPageList, loading } = useSelector(
    (state) => state.companyPage
  );

  const { userProfileInfo } = useSelector((state) => state.userProfile);

   useEffect(() => {
    dispatch(getAllCompanyPages())
   }, [])
  // useEffect(() => {
  //   if (publishingAsData) {
  //     setSelectedUser(publishingAsData);
  //   }
  //   if (publishingToData) {
  //     setPublishTo(publishingToData);
  //   }
  // }, [publishingAsData, publishingToData]);



  return (
    <Stack spacing={3}>
      <VStack
        gap={3}>
        <PostingUser
          profile={userProfileInfo}
          isCompany={false}
          isSelected={selectedUser}
          setValue={(e) => {setSelectedUser(e);}}
        />
        <VStack gap={3} maxH="300px" overflow="auto" w="100%">
          {!loading ? (
            companyPageList?.length && companyPageList ? (
              companyPageList?.map((company, i) => (
                <PostingUser
                  key={i}
                  profile={company}
                  isCompany={true}
                  isSelected={selectedUser}
                  setValue={(e) => {setSelectedUser(e);}}
                />
              ))
            ) : (
              <></>
            )
          ) : (
            <SectionLoader />
          )}
          <HStack justifyContent="space-between" w="100%">
          <Link href="/page/create">
              <HStack p={2} gap={1}>
              <Box
                w={'40px'}
                h={'40px'}
                borderRadius="full"
                bg="#D9D9D9"
                display="flex"
                justifyContent="center"
                alignItems="center"
                mr={2}
              >
                <AddIcon color={"#3D5A80"}/>
              </Box>
           <Heading  fontSize={"18px"} fontWeight={"500"} color={"#3D5A80"}>
           {t('createPost.createPage') || "Create Page"}
            </Heading>   
          </HStack>
          </Link> 
          </HStack>
        </VStack>
      </VStack>
      <hr />
      <VStack alignItems="flex-start" mb={3} w="100%">
      </VStack>
    </Stack>
  );
};

export default PostAsComponent;
